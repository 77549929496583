import React from 'react';
import {Typography} from "@mui/material";
import './aboutUsPage.css';

function AboutUsPage() {


  return (
      <div style={{
        backgroundImage: `url("kyiv_logo.jpg")`,
        backgroundPosition: 'center',
        marginTop: 5,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '80vh'
      }}>
        <div className="about-text" style={ {textAlign: 'center', padding: '5% 5% 10% 5%'} }>
          <Typography style={ { marginTop: 20, color: 'white', fontSize: 25, fontWeight: 'bold' } }>
            Детальніше про нас
          </Typography>
          <Typography style={ { marginTop: 20, color: 'white', fontSize: 15, fontWeight: 'bold',
          fontStyle: 'italic'} }>
            Наш головний пріоритет - безпека та комфорт пасажирів!
          </Typography>
        </div>
        {/*-----------------------------------------------------------------------------*/}
        <div className="about-text1" style={ {textAlign: 'center', padding: '5% 5% 10% 5%'} }>
          <Typography style={ { marginTop: 20, color: 'white', fontSize: 45, fontWeight: 'bold' } }>
            Детальніше про нас
          </Typography>
          <Typography style={ { marginTop: 20, color: 'white', fontSize: 20, fontWeight: 'bold',
            fontStyle: 'italic'} }>
            Ми ставимо на перше місце безпеку та комфорт наших пасажирів!
          </Typography>
        </div>


        {/*-----------------------------------------------------------------------------*/}
        <div style={ { backgroundColor: '#212121', margin: '3% 2% 1% 2%'}}>
        <div style={ {display: 'flex', justifyContent: 'center', padding: '2vh 2vw 2vh 2vw' ,
        } }>
          <div style={ {padding: '0.1vw', border: '2px solid white', textAlign: 'center' } }>
            <Typography style={ { color: 'white', fontSize: '4vw' } }>
              Mercedes Vito
            </Typography>
            <img src="/merc.jpg" width={'100%'} alt="logo"/>
          </div>
          <div style={ {width: '10vw', textAlign: 'center'} }>
          </div>
          <div style={ {padding: '0.1vw', border: '2px solid white', textAlign: 'center' } }>
            <Typography style={ { color: 'white', fontSize: '4vw' } }>
              Opel Vivaro
            </Typography>
            <img src="/opel_1.jpg" width={'100%'} alt="logo"/>
          </div>
        </div>
          {/*---------------------------------------------------------------*/}
          <div className="about-text2" style={ {textAlign: 'center', padding: '0% 3% 2% 3%'} }>
            <Typography style={ { color: 'white', fontSize: '2.7vw', fontStyle: 'italic' } }>
              Подорожуйте в сучасних автомобілях з максимальним комфортом
              і впевненістю у надійності кожної поїздки
            </Typography>
          </div>
          {/*---------------------------------------------------------------*/}
          <div className="about-text21"  style={ {textAlign: 'center', padding: '0% 3% 2% 3%'} }>
            <Typography style={ { color: 'white', fontSize: '1.3vw', fontStyle: 'italic' } }>
              Подорожуйте в сучасних автомобілях з максимальним комфортом
              і впевненістю у надійності кожної поїздки
            </Typography>
          </div>
          {/*---------------------------------------------------------------*/}

        </div>

      </div>
  );
}

export default AboutUsPage;
