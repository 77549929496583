import React from 'react';
import {AppBar, Box, Button, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContactFooter from "./components/contact-footer";
import MainPage from "./main-page";
import AboutUsPage from "./about-us-page";
import ServicePage from "./service-page";
import './App.css';

function App() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [pageComponent, setPageComponent] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (page: number) => {
    setPageComponent(page);
    handleClose();
  };

  return (
      <div>
        <div>
          <img src="/logo_1.jpg" width={'100%'} alt="logo"/>
        </div>
        <div>
          <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
              <Toolbar style={{backgroundColor: '#ffd54f', color: '#424242'}}>
                <IconButton
                    id="basic-button"
                    className="menu_icon"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{mr: 2}}
                >
                  <MenuIcon/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                >
                  <MenuItem onClick={()=>{handleClickMenu(1);}}>ГОЛОВНА</MenuItem>
                  <MenuItem onClick={()=>{handleClickMenu(2);}}>ПРО НАС</MenuItem>
                  <MenuItem onClick={()=>{handleClickMenu(3);}}>ПОСЛУГИ</MenuItem>
                </Menu>

                <Typography className="logo" style={ {fontSize: 30, fontWeight: 'bold'} }
                            sx={{flexGrow: 1}}>
                  VIP-ТАКСІ
                </Typography>
                <div className="menuButtons">
                <Button color="inherit" onClick={()=>{handleClickMenu(1);}}>ГОЛОВНА</Button>
                <Button color="inherit" onClick={()=>{handleClickMenu(2);}}>ПРО НАС</Button>
                <Button color="inherit" onClick={()=>{handleClickMenu(3);}}>ПОСЛУГИ</Button>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
        </div>
        <div>
          { pageComponent===1 ? <MainPage /> : null }
          { pageComponent===2 ? <AboutUsPage /> : null }
          { pageComponent===3 ? <ServicePage /> : null }
        </div>
        <div>
          <ContactFooter/>
        </div>
      </div>
  );
}

export default App;
