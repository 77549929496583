import React from 'react';
import {Typography} from "@mui/material";

function ScheduleBlock() {


  return (
      <div style={{backgroundColor: '#212121', marginTop: '10%', textAlign: 'center'}}>
        <Typography style={ { marginTop: 20, color: '#ffd54f', fontSize: 25, fontWeight: 'bold' } }>
          Розклад
        </Typography>
        <div style={ {display: 'flex', padding: '2% 3% 2% 3%', justifyContent: 'center'} }>
          <div style={ { borderTop: '2px solid #ffd54f'} }>
            <Typography style={ { marginTop: 20,color: '#ffd54f', fontSize: 18, fontWeight: 'bold' } }>
              Кропивницький
            </Typography>
            <Typography style={ { marginTop: 15,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>4:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>6:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>9:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>12:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>14:00</Typography>
          </div>
          <div style={ {width: '20vw', borderTop: '2px solid #ffd54f'} }></div>
          <div style={ {width: '20vw', borderTop: '2px solid #ffd54f'} }>
            <Typography style={ { marginTop: 20,color: '#ffd54f', fontSize: 18, fontWeight: 'bold' } }>
              Київ
            </Typography>
            <Typography style={ { marginTop: 15,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>12:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>14:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>16:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>18:00</Typography>
            <Typography style={ { marginTop: 5,color: '#ffd54f', fontSize: 16, fontWeight: 'bold' } }>19:00</Typography>

          </div>

        </div>
        <Typography style={ { marginTop: 5, color: '#ffd54f', fontSize: 18, fontWeight: 'bold' } }>
          Кропивницький - Київ    500 грн
        </Typography>
        <Typography style={ { marginTop: 5, color: '#ffd54f', fontSize: 18, fontWeight: 'bold' } }>
          Київ - Кропивницький    500 грн
        </Typography>
        <Typography style={ { marginTop: 5, color: '#ffd54f', fontSize: 14, fontWeight: 'bold',
        paddingBottom: 5 } }>
          * орієнтовний час у дорозі 4 години.
        </Typography>
      </div>
  );
}

export default ScheduleBlock;