import React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import {Avatar, Typography} from "@mui/material";
import './contact-footer.css';

function ContactFooter() {


  return (
      <div style={{backgroundColor: '#ffd54f', marginTop: 5}}>
        <div style={ {textAlign: 'center'} }>
          <Typography style={{ fontSize: '3vw' }}>
            Замовляйте поїздку прямо зараз!
          </Typography>
          <Typography style={ {fontStyle: 'italic', fontSize: '2vw', fontWeight: 'bold'} }>
            Звертайтеся за номером телефону:
          </Typography>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Avatar sx={{
            bgcolor: '#ffd54f',
            color: 'black',
            border: '2px solid black',
            marginTop: '3vh'
          }}>
            <TelegramIcon/>
          </Avatar>
          <Avatar sx={{
            bgcolor: '#ffd54f',
            color: 'black',
            border: '2px solid black',
            marginLeft: 2,
            marginTop: '3vh'
          }}>
            <WifiCalling3Icon/>
          </Avatar>
        </div>
        <div style={ {textAlign: 'center', marginTop: 5 } }>
          <Typography variant="h5">
            +380(95) 300-00-55
          </Typography>
          <Typography variant="h5">
            +380(96) 300-00-55
          </Typography>
        </div>
      </div>
  );
}

export default ContactFooter;