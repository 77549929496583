import React from 'react';
import {Typography} from "@mui/material";
import './aboutUsBlock.css';

function AboutUsBlock1() {


  return (
      <div className="wrapper1">

          <img src="/opel.jpg" width={'100%'} alt="logo"/>

        <div>
            <div style={ {display: 'flex', justifyContent: 'left', padding: '2% 7% 1% 7%'} }>
              <Typography style={ {fontSize: '2.2vw', fontWeight: 'bold'} } >
                Про нас
              </Typography>
            </div>
          {/*------------------------------------------------------------------------*/}
            <div className="text1" style={ { padding: '1% 4% 1% 4%'} }>
              <Typography  style={ {fontSize: '2vw', fontWeight: 'bold' } }>
                Компанія "VIP-таксі" заснована в 2008 році.
                Ми виконуємо пасажирські перевезення за напрямком Кропивницький - Київ - Кропивницький.
              </Typography>
            </div>
            <div className="text1" style={ { padding: '1% 4% 1% 4%'} }>
              <Typography style={ {fontSize: '2vw', fontWeight: 'bold' } }>
                Основні пріоритети нашої компанії це безпека на дорозі та комфорт пасажирів.
                Всі автомобілі нашого автопарку проходять ретельний технічний контроль,
                а пасажири нашої компанії мають страховий поліс. Всі наші водії мають великий
                безаварійний досвід роботи та гарантують Вам поїздку в комфортній атмосфері.
              </Typography>
            </div>

            <div className="text1" style={ { padding: '1% 4% 1% 4%'} }>
              <Typography style={ {fontSize: '2vw', fontWeight: 'bold' } }>
                В разі потреби надаємо всі документи для звітності по відрядженню.
                Окремою послугою можливе замовлення автомобіля в любу точку України.
              </Typography>
            </div>
            <div className="text1" style={ { padding: '1% 4% 0% 4%'} }>
              <Typography  style={ {fontSize: '3vw', fontWeight: 'bold'} }>
                Ми працюємо для Вас!
              </Typography>
            </div>
          {/*------------------------------------------------------------------------*/}
          <div className="text11" style={ { padding: '0.5% 5% 0.5% 5%'} }>
            <Typography style={ {fontSize: '1.4vw' } }>
              Компанія "VIP-таксі" заснована в 2008 році.
              Ми виконуємо пасажирські перевезення за напрямком Кропивницький - Київ - Кропивницький.
            </Typography>
          </div>
          <div className="text11" style={ { padding: '0% 5% 0.5% 5%'} }>
            <Typography style={ {fontSize: '1.4vw' } }>
              Основні пріоритети нашої компанії це безпека на дорозі та комфорт пасажирів.
              Всі автомобілі нашого автопарку проходять ретельний технічний контроль,
              а пасажири нашої компанії мають страховий поліс. Всі наші водії мають великий
              безаварійний досвід роботи та гарантують Вам поїздку в комфортній атмосфері.
            </Typography>
          </div>

          <div className="text11" style={ { padding: '0.5% 5% 0.5% 5%'} }>
            <Typography style={ {fontSize: '1.4vw' } }>
              В разі потреби надаємо всі документи для звітності по відрядженню.
              Окремою послугою можливе замовлення автомобіля в любу точку України.
            </Typography>
          </div>
          <div className="text11" style={ { padding: '1% 7% 0% 7%'} }>
            <Typography style={ {fontSize: '1.3vw', fontWeight: 'bold'} }>
              Ми працюємо для Вас!
            </Typography>
          </div>
        </div>
        </div>
  );
}

export default AboutUsBlock1;